import React, { useMemo } from "react";
import { chain, groupBy, map, sortBy } from "lodash";

import Layout from "../../components/3rd/layout";
import { Prime } from "../../components/Prime";
import SEO from "../../components/seo";
import Wrapper from "../../components/wrapper";
import { classNames } from "../../utils/string_utils";
import styles from "./results.module.scss";

export default () => {
  return (
    <Layout>
      <SEO title="第三回BMS衆議院選 開票結果" />
      <Wrapper className={styles.wrapper}>
        <a href="/3rd" className={styles.logo} style={{ marginBottom: 48 }}>
          <img src="/images/3rd/logo.png" alt="" />
        </a>

        <div className={styles.prime_wrapper}>
          <Prime
            className={styles.prime}
            party="ポップス党"
            minister="qfeileadh feat.kei_iwata"
          />
        </div>
        <section>
          <h2>政党別獲得議席</h2>
          <a
            href="/images/3rd/chart.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/3rd/chart.png"
              className={styles.chart}
              alt="政党別獲得議席"
            />
          </a>
        </section>
        <section>
          <h2>個別順位</h2>
          {sorted.map((t) => {
            return (
              <section key={t.team}>
                <h3>{t.team}</h3>
                <table className={styles.team_table} style={{ marginTop: 40 }}>
                  <thead>
                    <tr>
                      <th>当落</th>
                      <th>順位</th>
                      <th>Title</th>
                      <th>Total</th>
                      <th>Ave.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {t.bmses.map((bms, i) => {
                      const elected = bms.rank <= 60;
                      return (
                        <tr
                          key={bms.number}
                          className={classNames(
                            elected ? styles.elected : undefined
                          )}
                        >
                          <td className={styles.election}>
                            {elected ? (
                              <img
                                src="/images/elected.png"
                                alt=""
                                className={styles.elected_sign}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{bms.rank}</td>
                          <td className={styles.title}>
                            {bms.Title}
                            <br />/{bms.Artist}
                          </td>
                          <td>{bms.Total}</td>
                          <td>{bms.Average.toFixed(2)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            );
          })}
        </section>
      </Wrapper>
    </Layout>
  );
};

interface Data {
  number: number;
  Team: string;
  Genre: string;
  Title: string;
  Artist: string;
  subArtist: string;
  Total: number;
  Average: number;
  impressoinCount: number;
  commentCount: number;
  averageWeight: number;
  totalAve: number;
  rank: number;
  isElected: string;
}

const data: Data[] = [
  {
    number: 82,
    Team: "メロディ党♪",
    Genre: "Flat design.",
    Title: "クエンさん＊ガバメント",
    Artist: "黄泉路テヂーモ",
    subArtist: "BGA : 出前",
    Total: 340,
    Average: 4.657534247,
    impressoinCount: 73,
    commentCount: 1,
    averageWeight: 0.9315068493,
    totalAve: 340.9315068,
    rank: 1,
    isElected: "TRUE",
  },
  {
    number: 17,
    Team: "無所属",
    Genre: "Huture Walk",
    Title: "Always Feel the Same",
    Artist: "Gsus x VJ PONDELION",
    subArtist: "",
    Total: 319,
    Average: 4.76119403,
    impressoinCount: 67,
    commentCount: 0,
    averageWeight: 0.952238806,
    totalAve: 319.9522388,
    rank: 2,
    isElected: "TRUE",
  },
  {
    number: 109,
    Team: "メロディ党♪",
    Genre: "DRUM'N'BASS",
    Title: "Σxilio",
    Artist: "SOMON",
    subArtist: "",
    Total: 318,
    Average: 4.478873239,
    impressoinCount: 71,
    commentCount: 1,
    averageWeight: 0.8957746479,
    totalAve: 318.8957746,
    rank: 3,
    isElected: "TRUE",
  },
  {
    number: 96,
    Team: "ハウス党",
    Genre: "BRAZILIAN HOUSE",
    Title: "céu",
    Artist: "Kyuzo Sameura feat. きりたん",
    subArtist: "",
    Total: 305,
    Average: 4.692307692,
    impressoinCount: 65,
    commentCount: 1,
    averageWeight: 0.9384615385,
    totalAve: 305.9384615,
    rank: 4,
    isElected: "TRUE",
  },
  {
    number: 54,
    Team: "俺の推しを見ろ党",
    Genre: "SHINY FUTURE POP",
    Title: "Cong＊Chu＊Jelly",
    Artist: "otoshi.b × みゅい",
    subArtist: "illust：はしたかさん",
    Total: 302,
    Average: 4.575757576,
    impressoinCount: 66,
    commentCount: 0,
    averageWeight: 0.9151515152,
    totalAve: 302.9151515,
    rank: 5,
    isElected: "TRUE",
  },
  {
    number: 112,
    Team: "ポップス党",
    Genre: "BOYS FUTURE J-POP",
    Title: "明日をけっとばせ！",
    Artist: "qfeileadh feat.kei_iwata",
    subArtist: "mov:Pelican visual lab.",
    Total: 297,
    Average: 4.790322581,
    impressoinCount: 62,
    commentCount: 2,
    averageWeight: 0.9580645161,
    totalAve: 297.9580645,
    rank: 6,
    isElected: "TRUE",
  },
  {
    number: 95,
    Team: "和風党",
    Genre: "Orientalcore",
    Title: "燃ゆる白狐、匍う大蛇",
    Artist: "えくしい",
    subArtist: "obj.YOURI",
    Total: 286,
    Average: 4.53968254,
    impressoinCount: 63,
    commentCount: 0,
    averageWeight: 0.9079365079,
    totalAve: 286.9079365,
    rank: 7,
    isElected: "TRUE",
  },
  {
    number: 16,
    Team: "ハウス党",
    Genre: "J-HOUSE",
    Title: "Raindrops",
    Artist: "ag feat. 倉先",
    subArtist: "",
    Total: 280,
    Average: 4.666666667,
    impressoinCount: 60,
    commentCount: 0,
    averageWeight: 0.9333333333,
    totalAve: 280.9333333,
    rank: 8,
    isElected: "TRUE",
  },
  {
    number: 57,
    Team: "ユーロビー党",
    Genre: "EUROBEAT",
    Title: "神様の恋心",
    Artist: "nmk feat.一花",
    subArtist: "Illust:りーふぱい/BGA:わっふゑ",
    Total: 272,
    Average: 4.610169492,
    impressoinCount: 59,
    commentCount: 1,
    averageWeight: 0.9220338983,
    totalAve: 272.9220339,
    rank: 9,
    isElected: "TRUE",
  },
  {
    number: 14,
    Team: "俺の推しを見ろ党",
    Genre: "ORIENTAL CORE",
    Title: "摩訶幻想マジトリク",
    Artist: "戒 + テオン",
    subArtist: "",
    Total: 261,
    Average: 4.578947368,
    impressoinCount: 57,
    commentCount: 0,
    averageWeight: 0.9157894737,
    totalAve: 261.9157895,
    rank: 10,
    isElected: "TRUE",
  },
  {
    number: 52,
    Team: "銀行強党2",
    Genre: "Drum'n'bass",
    Title: "DEATH SENTENCE",
    Artist: "kohaxy",
    subArtist: "",
    Total: 255,
    Average: 4.553571429,
    impressoinCount: 56,
    commentCount: 0,
    averageWeight: 0.9107142857,
    totalAve: 255.9107143,
    rank: 11,
    isElected: "TRUE",
  },
  {
    number: 73,
    Team: "シンフォニック党",
    Genre: "RENAISSANCE",
    Title: "RTTR",
    Artist: "69 de 74",
    subArtist: "BGA:Arishima_Caprico",
    Total: 253,
    Average: 4.362068966,
    impressoinCount: 58,
    commentCount: 3,
    averageWeight: 0.8724137931,
    totalAve: 253.8724138,
    rank: 12,
    isElected: "TRUE",
  },
  {
    number: 94,
    Team: "シンフォニック党",
    Genre: "Fairytale Orchestra",
    Title: "Welcome to Wonderland",
    Artist: "BBBB",
    subArtist: "BGI:romusann",
    Total: 243,
    Average: 4.5,
    impressoinCount: 54,
    commentCount: 4,
    averageWeight: 0.9,
    totalAve: 243.9,
    rank: 13,
    isElected: "TRUE",
  },
  {
    number: 34,
    Team: "ポップス党",
    Genre: "Nostalgic J-POP",
    Title: "REZIDUAL MiND",
    Artist: "あとぅす feat.つゆり花鈴",
    subArtist: "BGI：りーふぱいザウルス",
    Total: 237,
    Average: 4.388888889,
    impressoinCount: 54,
    commentCount: 0,
    averageWeight: 0.8777777778,
    totalAve: 237.8777778,
    rank: 14,
    isElected: "TRUE",
  },
  {
    number: 80,
    Team: "ポップス党",
    Genre: "future pop",
    Title: "プラネット",
    Artist: "あまつち探検隊（Arkplot + 杠葉えりか）",
    subArtist: "",
    Total: 236,
    Average: 4.452830189,
    impressoinCount: 53,
    commentCount: 1,
    averageWeight: 0.8905660377,
    totalAve: 236.890566,
    rank: 15,
    isElected: "TRUE",
  },
  {
    number: 108,
    Team: "メロディ党♪",
    Genre: "Piano Trance",
    Title: "Prayer",
    Artist: "Kankitsu",
    subArtist: "",
    Total: 232,
    Average: 4.64,
    impressoinCount: 50,
    commentCount: 2,
    averageWeight: 0.928,
    totalAve: 232.928,
    rank: 16,
    isElected: "TRUE",
  },
  {
    number: 51,
    Team: "無所属",
    Genre: "ANARCHIC FREEFORM",
    Title: "CRUSH THE PARTY",
    Artist: "paraneumann",
    subArtist: "",
    Total: 227,
    Average: 4.283018868,
    impressoinCount: 53,
    commentCount: 0,
    averageWeight: 0.8566037736,
    totalAve: 227.8566038,
    rank: 17,
    isElected: "TRUE",
  },
  {
    number: 113,
    Team: "混合拍子党",
    Genre: "Irregular Rag",
    Title: "Dog-Run Panic!",
    Artist: "kas_0120",
    subArtist: "bgi: hard",
    Total: 221,
    Average: 4.42,
    impressoinCount: 50,
    commentCount: 0,
    averageWeight: 0.884,
    totalAve: 221.884,
    rank: 18,
    isElected: "TRUE",
  },
  {
    number: 67,
    Team: "メロディ党♪",
    Genre: "FUTURE FUSION",
    Title: "Gretel",
    Artist: "Qayo",
    subArtist: "",
    Total: 221,
    Average: 4.333333333,
    impressoinCount: 51,
    commentCount: 2,
    averageWeight: 0.8666666667,
    totalAve: 221.8666667,
    rank: 19,
    isElected: "TRUE",
  },
  {
    number: 90,
    Team: "冷飯党",
    Genre: "Cold Rice and",
    Title: "Sweet Pie",
    Artist: "Zris",
    subArtist: "",
    Total: 218,
    Average: 4.638297872,
    impressoinCount: 47,
    commentCount: 0,
    averageWeight: 0.9276595745,
    totalAve: 218.9276596,
    rank: 20,
    isElected: "TRUE",
  },
  {
    number: 50,
    Team: "Trance党",
    Genre: "Uplifting Trance",
    Title: "Over Limit",
    Artist: "Trinity",
    subArtist: "obj.BAECON57",
    Total: 218,
    Average: 4.274509804,
    impressoinCount: 51,
    commentCount: 1,
    averageWeight: 0.8549019608,
    totalAve: 218.854902,
    rank: 21,
    isElected: "TRUE",
  },
  {
    number: 8,
    Team: "和風党",
    Genre: "Japanese POP",
    Title: "あやめぐさ",
    Artist: "nonomori feat. よみ人知らず",
    subArtist: "",
    Total: 217,
    Average: 4.34,
    impressoinCount: 50,
    commentCount: 1,
    averageWeight: 0.868,
    totalAve: 217.868,
    rank: 22,
    isElected: "TRUE",
  },
  {
    number: 71,
    Team: "Djen党",
    Genre: "Whimsy Djent",
    Title: "Catricious",
    Artist: "Volmine",
    subArtist: "mov.ﾇﾎﾟｿﾝ半島",
    Total: 214,
    Average: 4.367346939,
    impressoinCount: 49,
    commentCount: 0,
    averageWeight: 0.8734693878,
    totalAve: 214.8734694,
    rank: 23,
    isElected: "TRUE",
  },
  {
    number: 26,
    Team: "無所属",
    Genre: "d'n'b",
    Title: "fig. 5.0E+15",
    Artist: "5000兆yen",
    subArtist: "BGA : C + letsu + tez",
    Total: 213,
    Average: 4.346938776,
    impressoinCount: 49,
    commentCount: 0,
    averageWeight: 0.8693877551,
    totalAve: 213.8693878,
    rank: 24,
    isElected: "TRUE",
  },
  {
    number: 25,
    Team: "冷飯党",
    Genre: "CHIP FANTASIA",
    Title: "BLUEPOTION-FANTASIA *2019*",
    Artist: "Kamisys",
    subArtist: "",
    Total: 211,
    Average: 4.306122449,
    impressoinCount: 49,
    commentCount: 0,
    averageWeight: 0.8612244898,
    totalAve: 211.8612245,
    rank: 25,
    isElected: "TRUE",
  },
  {
    number: 6,
    Team: "Trance党",
    Genre: "TRANCE",
    Title: "Mercury's Feelings",
    Artist: "U-hey Seta",
    subArtist: "Movie:Nao_AnD",
    Total: 211,
    Average: 4.22,
    impressoinCount: 50,
    commentCount: 2,
    averageWeight: 0.844,
    totalAve: 211.844,
    rank: 26,
    isElected: "TRUE",
  },
  {
    number: 29,
    Team: "縦連打党",
    Genre: "VERTICAL UNLIMITED",
    Title: "Mr.RENDA",
    Artist: "縦連田 勝己",
    subArtist: "DP:spine",
    Total: 211,
    Average: 3.981132075,
    impressoinCount: 53,
    commentCount: 0,
    averageWeight: 0.7962264151,
    totalAve: 211.7962264,
    rank: 27,
    isElected: "TRUE",
  },
  {
    number: 1,
    Team: "GAY FURRIES UNITED",
    Genre: "Dubstep",
    Title: "LET ME FIRST INTRODUCE:",
    Artist: "Nakaiankow",
    subArtist: "",
    Total: 209,
    Average: 4.18,
    impressoinCount: 50,
    commentCount: 0,
    averageWeight: 0.836,
    totalAve: 209.836,
    rank: 28,
    isElected: "TRUE",
  },
  {
    number: 4,
    Team: "銀行強党2",
    Genre: "Drum’n Bass",
    Title: "Raider×Outlaw",
    Artist: "KAH",
    subArtist: "",
    Total: 208,
    Average: 4.16,
    impressoinCount: 50,
    commentCount: 0,
    averageWeight: 0.832,
    totalAve: 208.832,
    rank: 29,
    isElected: "TRUE",
  },
  {
    number: 33,
    Team: "Kawaii党",
    Genre: "Kawaii Future Bass",
    Title: "Lovelycharm",
    Artist: "4saface",
    subArtist: "mov. 自動車 原付",
    Total: 207,
    Average: 4.3125,
    impressoinCount: 48,
    commentCount: 1,
    averageWeight: 0.8625,
    totalAve: 207.8625,
    rank: 30,
    isElected: "TRUE",
  },
  {
    number: 58,
    Team: "俺の推しを見ろ党",
    Genre: "Alternative Loud",
    Title: "残照",
    Artist: "ume",
    subArtist: "",
    Total: 206,
    Average: 4.204081633,
    impressoinCount: 49,
    commentCount: 0,
    averageWeight: 0.8408163265,
    totalAve: 206.8408163,
    rank: 31,
    isElected: "TRUE",
  },
  {
    number: 99,
    Team: "ポップス党",
    Genre: "J-Pop Rock",
    Title: "brandnew Wave",
    Artist: "Aliesrite* (Ym1024 feat. lamie*)",
    subArtist: "",
    Total: 205,
    Average: 4.361702128,
    impressoinCount: 47,
    commentCount: 0,
    averageWeight: 0.8723404255,
    totalAve: 205.8723404,
    rank: 32,
    isElected: "TRUE",
  },
  {
    number: 110,
    Team: "ポップス党",
    Genre: "Farewell",
    Title: "フウセンのユメ",
    Artist: "kei_iwata feat.わかどり",
    subArtist: "mov:もがみ",
    Total: 205,
    Average: 4.270833333,
    impressoinCount: 48,
    commentCount: 1,
    averageWeight: 0.8541666667,
    totalAve: 205.8541667,
    rank: 33,
    isElected: "TRUE",
  },
  {
    number: 59,
    Team: "無所属",
    Genre: "TECHNO",
    Title: "Working Hard at the Warehouse",
    Artist: "Dolphin",
    subArtist: "",
    Total: 199,
    Average: 4.326086957,
    impressoinCount: 46,
    commentCount: 0,
    averageWeight: 0.8652173913,
    totalAve: 199.8652174,
    rank: 34,
    isElected: "TRUE",
  },
  {
    number: 83,
    Team: "NOISE / GLITCH党",
    Genre: "ARTTRONICA (NOISE)",
    Title: "Clear stream in a distorted world",
    Artist: "Hyparpax",
    subArtist: "",
    Total: 199,
    Average: 4.326086957,
    impressoinCount: 46,
    commentCount: 0,
    averageWeight: 0.8652173913,
    totalAve: 199.8652174,
    rank: 34,
    isElected: "TRUE",
  },
  {
    number: 27,
    Team: "Trance党",
    Genre: "HARD TRANCE",
    Title: "Punishment (BMS Edit.)",
    Artist: "KaKi",
    subArtist: "",
    Total: 198,
    Average: 4.304347826,
    impressoinCount: 46,
    commentCount: 2,
    averageWeight: 0.8608695652,
    totalAve: 198.8608696,
    rank: 36,
    isElected: "TRUE",
  },
  {
    number: 21,
    Team: "無所属",
    Genre: "Senegalean Romance",
    Title: "One Kiss & True Lovers",
    Artist: "0310 / たれゆ / nor",
    subArtist: "",
    Total: 197,
    Average: 4.191489362,
    impressoinCount: 47,
    commentCount: 0,
    averageWeight: 0.8382978723,
    totalAve: 197.8382979,
    rank: 37,
    isElected: "TRUE",
  },
  {
    number: 13,
    Team: "無所属",
    Genre: "Glitch Hop",
    Title: "GinFix",
    Artist: "Drumatic Sequener & Jade",
    subArtist: "",
    Total: 195,
    Average: 4.14893617,
    impressoinCount: 47,
    commentCount: 0,
    averageWeight: 0.829787234,
    totalAve: 195.8297872,
    rank: 38,
    isElected: "TRUE",
  },
  {
    number: 3,
    Team: "無所属",
    Genre: "EURO RAVE",
    Title: "（ ￥ ∀ ￥ ）",
    Artist: "TaW",
    subArtist: "obj(INSANE):Lennon",
    Total: 195,
    Average: 3.679245283,
    impressoinCount: 53,
    commentCount: 0,
    averageWeight: 0.7358490566,
    totalAve: 195.7358491,
    rank: 39,
    isElected: "TRUE",
  },
  {
    number: 31,
    Team: "ハウス党",
    Genre: "Afro House / Lounge",
    Title: "Twilight Ocean (Radio Edit)",
    Artist: "七草くりむ",
    subArtist: "",
    Total: 194,
    Average: 3.959183673,
    impressoinCount: 49,
    commentCount: 0,
    averageWeight: 0.7918367347,
    totalAve: 194.7918367,
    rank: 40,
    isElected: "TRUE",
  },
  {
    number: 11,
    Team: "シンフォニック党",
    Genre: "Epic Orchestra",
    Title: "青龍 - Qinglong -",
    Artist: "mots",
    subArtist: "",
    Total: 192,
    Average: 4,
    impressoinCount: 48,
    commentCount: 3,
    averageWeight: 0.8,
    totalAve: 192.8,
    rank: 41,
    isElected: "TRUE",
  },
  {
    number: 60,
    Team: "シンフォニック党",
    Genre: "In Honor of Aram Khachaturyan",
    Title: "1913 Schneider",
    Artist: "自由落下(FreeFall)",
    subArtist: "",
    Total: 187,
    Average: 4.348837209,
    impressoinCount: 43,
    commentCount: 3,
    averageWeight: 0.8697674419,
    totalAve: 187.8697674,
    rank: 42,
    isElected: "TRUE",
  },
  {
    number: 47,
    Team: "UKHC党",
    Genre: "UK Hardcore",
    Title: "Acetone",
    Artist: "Sho Fish",
    subArtist: "",
    Total: 187,
    Average: 3.895833333,
    impressoinCount: 48,
    commentCount: 0,
    averageWeight: 0.7791666667,
    totalAve: 187.7791667,
    rank: 43,
    isElected: "TRUE",
  },
  {
    number: 111,
    Team: "NOISE / GLITCH党",
    Genre: "ProjectG",
    Title: "G-Bass",
    Artist: "Theme",
    subArtist: "",
    Total: 185,
    Average: 4.204545455,
    impressoinCount: 44,
    commentCount: 2,
    averageWeight: 0.8409090909,
    totalAve: 185.8409091,
    rank: 44,
    isElected: "TRUE",
  },
  {
    number: 88,
    Team: "歪み党",
    Genre: "Rotterdam Techno",
    Title: "pi-tin×pi-tin",
    Artist: "TRIAL",
    subArtist: "BGA:KinCraft",
    Total: 185,
    Average: 3.854166667,
    impressoinCount: 48,
    commentCount: 0,
    averageWeight: 0.7708333333,
    totalAve: 185.7708333,
    rank: 45,
    isElected: "TRUE",
  },
  {
    number: 70,
    Team: "Djen党",
    Genre: "Psy/Djentrance",
    Title: "A mass of grudge",
    Artist: "kireji",
    subArtist: "",
    Total: 183,
    Average: 3.97826087,
    impressoinCount: 46,
    commentCount: 0,
    averageWeight: 0.7956521739,
    totalAve: 183.7956522,
    rank: 46,
    isElected: "TRUE",
  },
  {
    number: 97,
    Team: "猿トランス党",
    Genre: "China Trance meets Hardcore",
    Title: "Inversion",
    Artist: "Roxellana",
    subArtist: "",
    Total: 182,
    Average: 4.333333333,
    impressoinCount: 42,
    commentCount: 0,
    averageWeight: 0.8666666667,
    totalAve: 182.8666667,
    rank: 47,
    isElected: "TRUE",
  },
  {
    number: 43,
    Team: "ポップス党",
    Genre: "J-POP",
    Title: "旅の最果てに見る夢は",
    Artist: "後藤 feat. 初音ミク",
    subArtist: "illust : ﾙｰｼｰ",
    Total: 182,
    Average: 3.872340426,
    impressoinCount: 47,
    commentCount: 1,
    averageWeight: 0.7744680851,
    totalAve: 182.7744681,
    rank: 48,
    isElected: "TRUE",
  },
  {
    number: 48,
    Team: "ハウス党",
    Genre: "HOUSE",
    Title: "夕闇の街",
    Artist: "りょくちゃくん",
    subArtist: "",
    Total: 181,
    Average: 4.022222222,
    impressoinCount: 45,
    commentCount: 0,
    averageWeight: 0.8044444444,
    totalAve: 181.8044444,
    rank: 49,
    isElected: "TRUE",
  },
  {
    number: 12,
    Team: "ユーロビー党",
    Genre: "EUROBEAT",
    Title: "High on Love",
    Artist: "hororo",
    subArtist: "",
    Total: 181,
    Average: 4.022222222,
    impressoinCount: 45,
    commentCount: 0,
    averageWeight: 0.8044444444,
    totalAve: 181.8044444,
    rank: 49,
    isElected: "TRUE",
  },
  {
    number: 106,
    Team: "猿トランス党",
    Genre: "For all Primates",
    Title: "Experience of Ecstasy",
    Artist: "sukuramu42",
    subArtist: "",
    Total: 179,
    Average: 4.162790698,
    impressoinCount: 43,
    commentCount: 0,
    averageWeight: 0.8325581395,
    totalAve: 179.8325581,
    rank: 51,
    isElected: "TRUE",
  },
  {
    number: 75,
    Team: "シンフォニック党",
    Genre: "Cyber art",
    Title: "Fresco",
    Artist: "Lemi.",
    subArtist: "obj(Another):69 de 74",
    Total: 178,
    Average: 4.045454545,
    impressoinCount: 44,
    commentCount: 3,
    averageWeight: 0.8090909091,
    totalAve: 178.8090909,
    rank: 52,
    isElected: "TRUE",
  },
  {
    number: 84,
    Team: "UKHC党",
    Genre: "UK Hardcore",
    Title: "Lonely Overnight",
    Artist: "Ray Nautica",
    subArtist: "mov. もがみ",
    Total: 176,
    Average: 4.292682927,
    impressoinCount: 41,
    commentCount: 1,
    averageWeight: 0.8585365854,
    totalAve: 176.8585366,
    rank: 53,
    isElected: "TRUE",
  },
  {
    number: 86,
    Team: "混合拍子党",
    Genre: "lives",
    Title: "シンク",
    Artist: "Mr.Faine feat. kei_iwata",
    subArtist: "BGI: romusann",
    Total: 175,
    Average: 4.069767442,
    impressoinCount: 43,
    commentCount: 0,
    averageWeight: 0.8139534884,
    totalAve: 175.8139535,
    rank: 54,
    isElected: "TRUE",
  },
  {
    number: 18,
    Team: "無所属",
    Genre: "DECADENT STYLE RAGGAETON",
    Title: "bout ya dirty cheats",
    Artist: "4G",
    subArtist: "",
    Total: 171,
    Average: 3.976744186,
    impressoinCount: 43,
    commentCount: 0,
    averageWeight: 0.7953488372,
    totalAve: 171.7953488,
    rank: 55,
    isElected: "TRUE",
  },
  {
    number: 44,
    Team: "UKHC党",
    Genre: "UK HARDCORE",
    Title: "Cross-D Skyline",
    Artist: "Sephid",
    subArtist: "obj. AZVW_2",
    Total: 171,
    Average: 3.8,
    impressoinCount: 45,
    commentCount: 0,
    averageWeight: 0.76,
    totalAve: 171.76,
    rank: 56,
    isElected: "TRUE",
  },
  {
    number: 2,
    Team: "GAY FURRIES UNITED",
    Genre: "HARDCORE",
    Title: "ドリアン爆弾",
    Artist: "獸人控",
    subArtist: "obj.男の娘控",
    Total: 170,
    Average: 3.953488372,
    impressoinCount: 43,
    commentCount: 0,
    averageWeight: 0.7906976744,
    totalAve: 170.7906977,
    rank: 57,
    isElected: "TRUE",
  },
  {
    number: 79,
    Team: "銀行強党2",
    Genre: "BIG BEAT / MIXTURE",
    Title: "Suppress and Oppress",
    Artist: "uet",
    subArtist: "",
    Total: 169,
    Average: 4.023809524,
    impressoinCount: 42,
    commentCount: 0,
    averageWeight: 0.8047619048,
    totalAve: 169.8047619,
    rank: 58,
    isElected: "TRUE",
  },
  {
    number: 85,
    Team: "歪み党",
    Genre: "Breakcore/Ambient Gabbar",
    Title: "デジタル降神術",
    Artist: "でぱみぃ",
    subArtist: "",
    Total: 167,
    Average: 3.976190476,
    impressoinCount: 42,
    commentCount: 1,
    averageWeight: 0.7952380952,
    totalAve: 167.7952381,
    rank: 59,
    isElected: "TRUE",
  },
  {
    number: 72,
    Team: "猿トランス党",
    Genre: "MUSIC GAME TRADITIONAL",
    Title: "Mondlicht",
    Artist: "Nycticebus",
    subArtist: "",
    Total: 166,
    Average: 4.15,
    impressoinCount: 40,
    commentCount: 0,
    averageWeight: 0.83,
    totalAve: 166.83,
    rank: 60,
    isElected: "TRUE",
  },
  {
    number: 53,
    Team: "和風党",
    Genre: "Oriental Hardcore",
    Title: "Amateru",
    Artist: "MoVIIkA",
    subArtist: "",
    Total: 166,
    Average: 3.772727273,
    impressoinCount: 44,
    commentCount: 0,
    averageWeight: 0.7545454545,
    totalAve: 166.7545455,
    rank: 61,
    isElected: "FALSE",
  },
  {
    number: 10,
    Team: "無所属",
    Genre: "Moombahton",
    Title: "吐露ピカル中毒",
    Artist: "ですわ",
    subArtist: "",
    Total: 166,
    Average: 3.688888889,
    impressoinCount: 45,
    commentCount: 0,
    averageWeight: 0.7377777778,
    totalAve: 166.7377778,
    rank: 62,
    isElected: "FALSE",
  },
  {
    number: 39,
    Team: "猿トランス党",
    Genre: "HARD TRANCE",
    Title: "The Saru Paradice",
    Artist: "Marmoset",
    subArtist: "",
    Total: 164,
    Average: 3.644444444,
    impressoinCount: 45,
    commentCount: 1,
    averageWeight: 0.7288888889,
    totalAve: 164.7288889,
    rank: 63,
    isElected: "FALSE",
  },
  {
    number: 46,
    Team: "俺の推しを見ろ党",
    Genre: "Trance",
    Title: "この広い空に。",
    Artist: "Ryu0316 a.k.a. 竜。 feat.結月ゆかり",
    subArtist: "",
    Total: 161,
    Average: 3.926829268,
    impressoinCount: 41,
    commentCount: 0,
    averageWeight: 0.7853658537,
    totalAve: 161.7853659,
    rank: 64,
    isElected: "FALSE",
  },
  {
    number: 19,
    Team: "無所属",
    Genre: "BEAT ONLY",
    Title: "WAV STACKING",
    Artist: "IBSPME",
    subArtist: "obj(9key):Khibine,BGI:十錠らぴす",
    Total: 161,
    Average: 3.744186047,
    impressoinCount: 43,
    commentCount: 0,
    averageWeight: 0.7488372093,
    totalAve: 161.7488372,
    rank: 65,
    isElected: "FALSE",
  },
  {
    number: 7,
    Team: "銀行強党2",
    Genre: "Funkot",
    Title: "Clowns Are Scary 2021",
    Artist: "hayato87b",
    subArtist: "",
    Total: 160,
    Average: 3.720930233,
    impressoinCount: 43,
    commentCount: 0,
    averageWeight: 0.7441860465,
    totalAve: 160.744186,
    rank: 66,
    isElected: "FALSE",
  },
  {
    number: 23,
    Team: "Djen党",
    Genre: "Djent/Metalcore",
    Title: "Unforgiveness for Human",
    Artist: "Kanata.S",
    subArtist: "",
    Total: 160,
    Average: 3.720930233,
    impressoinCount: 43,
    commentCount: 0,
    averageWeight: 0.7441860465,
    totalAve: 160.744186,
    rank: 66,
    isElected: "FALSE",
  },
  {
    number: 9,
    Team: "無所属",
    Genre: "EDO MONDO HOUSE",
    Title: "HONDA",
    Artist: "loca",
    subArtist: "",
    Total: 160,
    Average: 3.555555556,
    impressoinCount: 45,
    commentCount: 0,
    averageWeight: 0.7111111111,
    totalAve: 160.7111111,
    rank: 68,
    isElected: "FALSE",
  },
  {
    number: 56,
    Team: "Trance党",
    Genre: "Uplifting Trance",
    Title: "黄昏ノ丘",
    Artist: "Zikura",
    subArtist: "",
    Total: 157,
    Average: 4.243243243,
    impressoinCount: 37,
    commentCount: 2,
    averageWeight: 0.8486486486,
    totalAve: 157.8486486,
    rank: 69,
    isElected: "FALSE",
  },
  {
    number: 62,
    Team: "ポップス党",
    Genre: "pop",
    Title: "ビターラブシンドローム",
    Artist: "志熊けい",
    subArtist: "",
    Total: 154,
    Average: 4.052631579,
    impressoinCount: 38,
    commentCount: 0,
    averageWeight: 0.8105263158,
    totalAve: 154.8105263,
    rank: 70,
    isElected: "FALSE",
  },
  {
    number: 101,
    Team: "Trance党",
    Genre: "TRANCE",
    Title: "Dawn of the Cosmos",
    Artist: "MONO",
    subArtist: "",
    Total: 153,
    Average: 3.923076923,
    impressoinCount: 39,
    commentCount: 3,
    averageWeight: 0.7846153846,
    totalAve: 153.7846154,
    rank: 71,
    isElected: "FALSE",
  },
  {
    number: 103,
    Team: "Kawaii党",
    Genre: "kawaii Tech Electro",
    Title: "Falling Cxte",
    Artist: "Qga",
    subArtist: "BGI.N O T A",
    Total: 152,
    Average: 4,
    impressoinCount: 38,
    commentCount: 3,
    averageWeight: 0.8,
    totalAve: 152.8,
    rank: 72,
    isElected: "FALSE",
  },
  {
    number: 42,
    Team: "猿トランス党",
    Genre: "TRANCE",
    Title: "Spherescense",
    Artist: "DJ Aye^2",
    subArtist: "VJ Giraffe",
    Total: 151,
    Average: 3.973684211,
    impressoinCount: 38,
    commentCount: 0,
    averageWeight: 0.7947368421,
    totalAve: 151.7947368,
    rank: 73,
    isElected: "FALSE",
  },
  {
    number: 104,
    Team: "無所属",
    Genre: "NU JAZZ",
    Title: "IN MY SOUL",
    Artist: "ANKAKE",
    subArtist: "",
    Total: 150,
    Average: 4.054054054,
    impressoinCount: 37,
    commentCount: 1,
    averageWeight: 0.8108108108,
    totalAve: 150.8108108,
    rank: 74,
    isElected: "FALSE",
  },
  {
    number: 77,
    Team: "無所属",
    Genre: "Bass Music",
    Title: "Iris",
    Artist: "A plus B equal to C x Alnilam",
    subArtist: "",
    Total: 149,
    Average: 3.725,
    impressoinCount: 40,
    commentCount: 0,
    averageWeight: 0.745,
    totalAve: 149.745,
    rank: 75,
    isElected: "FALSE",
  },
  {
    number: 64,
    Team: "ハウス党",
    Genre: "house",
    Title: "The avenue",
    Artist: "M-kani",
    subArtist: "",
    Total: 149,
    Average: 3.634146341,
    impressoinCount: 41,
    commentCount: 0,
    averageWeight: 0.7268292683,
    totalAve: 149.7268293,
    rank: 76,
    isElected: "FALSE",
  },
  {
    number: 81,
    Team: "Kawaii党",
    Genre: "PSYCHEDELIC",
    Title: "呪いのおもちゃ",
    Artist: "center9",
    subArtist: "",
    Total: 149,
    Average: 3.465116279,
    impressoinCount: 43,
    commentCount: 3,
    averageWeight: 0.6930232558,
    totalAve: 149.6930233,
    rank: 77,
    isElected: "FALSE",
  },
  {
    number: 102,
    Team: "無所属",
    Genre: "ACID JAZZ HOUSE",
    Title: "ALL NIGHT LONG",
    Artist: "zatsuzatsu",
    subArtist: "",
    Total: 145,
    Average: 3.815789474,
    impressoinCount: 38,
    commentCount: 1,
    averageWeight: 0.7631578947,
    totalAve: 145.7631579,
    rank: 78,
    isElected: "FALSE",
  },
  {
    number: 15,
    Team: "Trance党",
    Genre: "TRANCE",
    Title: "Dawning Orange",
    Artist: "Snack",
    subArtist: "",
    Total: 145,
    Average: 3.625,
    impressoinCount: 40,
    commentCount: 2,
    averageWeight: 0.725,
    totalAve: 145.725,
    rank: 79,
    isElected: "FALSE",
  },
  {
    number: 74,
    Team: "混合拍子党",
    Genre: "11beat vs 8beat",
    Title: "Erratic Erosion",
    Artist: "MI-OH",
    subArtist: "",
    Total: 144,
    Average: 3.891891892,
    impressoinCount: 37,
    commentCount: 0,
    averageWeight: 0.7783783784,
    totalAve: 144.7783784,
    rank: 80,
    isElected: "FALSE",
  },
  {
    number: 69,
    Team: "Trance党",
    Genre: "Euphoric Trance",
    Title: "Crossover(Radio Edit)",
    Artist: "RYO-K a.k.a Kanikama",
    subArtist: "BGA:NI-X2",
    Total: 143,
    Average: 4.085714286,
    impressoinCount: 35,
    commentCount: 2,
    averageWeight: 0.8171428571,
    totalAve: 143.8171429,
    rank: 81,
    isElected: "FALSE",
  },
  {
    number: 38,
    Team: "縦連打党",
    Genre: "RENDA DISCO",
    Title: "RAIN DA LANE",
    Artist: "momo★yama",
    subArtist: "",
    Total: 143,
    Average: 3.575,
    impressoinCount: 40,
    commentCount: 0,
    averageWeight: 0.715,
    totalAve: 143.715,
    rank: 82,
    isElected: "FALSE",
  },
  {
    number: 20,
    Team: "銀行強党2",
    Genre: "FLAMETHROWER",
    Title: "The_Flames_of_Love($fhgfjf remix)",
    Artist: "Kyou1110",
    subArtist: "",
    Total: 140,
    Average: 3.684210526,
    impressoinCount: 38,
    commentCount: 0,
    averageWeight: 0.7368421053,
    totalAve: 140.7368421,
    rank: 83,
    isElected: "FALSE",
  },
  {
    number: 93,
    Team: "WESTERN党",
    Genre: "WESTERN TRAPSTEPCORE",
    Title: "SHOWDOWN AT HIGH NUWUN",
    Artist: "432pro",
    subArtist: "",
    Total: 140,
    Average: 3.58974359,
    impressoinCount: 39,
    commentCount: 0,
    averageWeight: 0.7179487179,
    totalAve: 140.7179487,
    rank: 84,
    isElected: "FALSE",
  },
  {
    number: 105,
    Team: "無所属",
    Genre: "ANTARCTIC SUDDEN BLIZZARD",
    Title: "驟雪",
    Artist: "前田龍之介",
    subArtist: "",
    Total: 138,
    Average: 3.833333333,
    impressoinCount: 36,
    commentCount: 0,
    averageWeight: 0.7666666667,
    totalAve: 138.7666667,
    rank: 85,
    isElected: "FALSE",
  },
  {
    number: 98,
    Team: "メロディ党♪",
    Genre: "Bouken Pop",
    Title: "One Of The Wonders",
    Artist: "かむちゃうらる",
    subArtist: "",
    Total: 137,
    Average: 3.805555556,
    impressoinCount: 36,
    commentCount: 2,
    averageWeight: 0.7611111111,
    totalAve: 137.7611111,
    rank: 86,
    isElected: "FALSE",
  },
  {
    number: 5,
    Team: "WESTERN党",
    Genre: "christian hymn",
    Title: "were you there, when they crucified my lord?",
    Artist: "trad. arr. lollipop",
    subArtist: "",
    Total: 137,
    Average: 3.702702703,
    impressoinCount: 37,
    commentCount: 1,
    averageWeight: 0.7405405405,
    totalAve: 137.7405405,
    rank: 87,
    isElected: "FALSE",
  },
  {
    number: 32,
    Team: "混合拍子党",
    Genre: "病入膏肓",
    Title: "花華肢",
    Artist: "一人デート",
    subArtist: "BGI: MUSH.T obj: NASA, BAECON",
    Total: 135,
    Average: 3.75,
    impressoinCount: 36,
    commentCount: 0,
    averageWeight: 0.75,
    totalAve: 135.75,
    rank: 88,
    isElected: "FALSE",
  },
  {
    number: 55,
    Team: "猿トランス党",
    Genre: "TRANCE",
    Title: "SaruDrive",
    Artist: "DJ マンドリル",
    subArtist: "",
    Total: 135,
    Average: 3.552631579,
    impressoinCount: 38,
    commentCount: 0,
    averageWeight: 0.7105263158,
    totalAve: 135.7105263,
    rank: 89,
    isElected: "FALSE",
  },
  {
    number: 100,
    Team: "歪み党",
    Genre: "【高音質】【重低音強化】",
    Title: "音圧丸",
    Artist: "くるやのぶ",
    subArtist: "",
    Total: 131,
    Average: 3.195121951,
    impressoinCount: 41,
    commentCount: 2,
    averageWeight: 0.6390243902,
    totalAve: 131.6390244,
    rank: 90,
    isElected: "FALSE",
  },
  {
    number: 61,
    Team: "ユーロビー党",
    Genre: "EUROBEAT",
    Title: "約束の時間",
    Artist: "ねぎとまと feat.東北イタコ",
    subArtist: "",
    Total: 129,
    Average: 3.394736842,
    impressoinCount: 38,
    commentCount: 0,
    averageWeight: 0.6789473684,
    totalAve: 129.6789474,
    rank: 91,
    isElected: "FALSE",
  },
  {
    number: 22,
    Team: "冷飯党",
    Genre: "花言葉",
    Title: "Rosa rubus ~L'Ultimo Fragranza~",
    Artist: "1N6Fs",
    subArtist: "",
    Total: 128,
    Average: 3.657142857,
    impressoinCount: 35,
    commentCount: 0,
    averageWeight: 0.7314285714,
    totalAve: 128.7314286,
    rank: 92,
    isElected: "FALSE",
  },
  {
    number: 49,
    Team: "歪み党",
    Genre: "CROSSBREED",
    Title: "TEMPEST",
    Artist: "FeNRIR darkside + tails of kingdoms",
    subArtist: "",
    Total: 127,
    Average: 3.432432432,
    impressoinCount: 37,
    commentCount: 0,
    averageWeight: 0.6864864865,
    totalAve: 127.6864865,
    rank: 93,
    isElected: "FALSE",
  },
  {
    number: 78,
    Team: "歪み党",
    Genre: "Dislike Human Core",
    Title: "薊",
    Artist: "Trantech",
    subArtist: "",
    Total: 125,
    Average: 3.378378378,
    impressoinCount: 37,
    commentCount: 1,
    averageWeight: 0.6756756757,
    totalAve: 125.6756757,
    rank: 94,
    isElected: "FALSE",
  },
  {
    number: 40,
    Team: "無所属",
    Genre: "Glitch Hop",
    Title: "Restart",
    Artist: "Kitsunetsuki House",
    subArtist: "",
    Total: 124,
    Average: 3.444444444,
    impressoinCount: 36,
    commentCount: 0,
    averageWeight: 0.6888888889,
    totalAve: 124.6888889,
    rank: 95,
    isElected: "FALSE",
  },
  {
    number: 37,
    Team: "WESTERN党",
    Genre: "trap landing",
    Title: "shlangin",
    Artist: "dandeless",
    subArtist: "",
    Total: 122,
    Average: 3.388888889,
    impressoinCount: 36,
    commentCount: 0,
    averageWeight: 0.6777777778,
    totalAve: 122.6777778,
    rank: 96,
    isElected: "FALSE",
  },
  {
    number: 65,
    Team: "メロディ党♪",
    Genre: "trancecore",
    Title: "origin",
    Artist: "Big Bamboo",
    subArtist: "",
    Total: 121,
    Average: 3.184210526,
    impressoinCount: 38,
    commentCount: 2,
    averageWeight: 0.6368421053,
    totalAve: 121.6368421,
    rank: 97,
    isElected: "FALSE",
  },
  {
    number: 36,
    Team: "UKHC党",
    Genre: "UK Hardcore",
    Title: "ReverieChaser",
    Artist: "Axya",
    subArtist: "mov. TARA#376",
    Total: 118,
    Average: 3.371428571,
    impressoinCount: 35,
    commentCount: 0,
    averageWeight: 0.6742857143,
    totalAve: 118.6742857,
    rank: 98,
    isElected: "FALSE",
  },
  {
    number: 68,
    Team: "銀行強党2",
    Genre: "HARDTEK",
    Title: "I Can't Feel Xmas",
    Artist: "九八式旋回機関銃",
    subArtist: "",
    Total: 116,
    Average: 3.411764706,
    impressoinCount: 34,
    commentCount: 0,
    averageWeight: 0.6823529412,
    totalAve: 116.6823529,
    rank: 99,
    isElected: "FALSE",
  },
  {
    number: 30,
    Team: "GAY FURRIES UNITED",
    Genre: "HARD DANCE",
    Title: "KEMONO BOUNCE",
    Artist: "Shae",
    subArtist: "BGI: wintermalen",
    Total: 116,
    Average: 2.761904762,
    impressoinCount: 42,
    commentCount: 0,
    averageWeight: 0.5523809524,
    totalAve: 116.552381,
    rank: 100,
    isElected: "FALSE",
  },
  {
    number: 66,
    Team: "混合拍子党",
    Genre: "こすもぽっぷす",
    Title: "あんばうんでぃっど☆あんのうんわーるど",
    Artist: "ザラエル feat. 音街ウナ",
    subArtist: "",
    Total: 115,
    Average: 3.382352941,
    impressoinCount: 34,
    commentCount: 0,
    averageWeight: 0.6764705882,
    totalAve: 115.6764706,
    rank: 101,
    isElected: "FALSE",
  },
  {
    number: 89,
    Team: "縦連打党",
    Genre: "Dark Step",
    Title: "gloom",
    Artist: "awawa",
    subArtist: "",
    Total: 115,
    Average: 3.194444444,
    impressoinCount: 36,
    commentCount: 0,
    averageWeight: 0.6388888889,
    totalAve: 115.6388889,
    rank: 102,
    isElected: "FALSE",
  },
  {
    number: 45,
    Team: "Trance党",
    Genre: "Uplifting Trance",
    Title: "Fall To The Abgrund",
    Artist: "Eltsopa",
    subArtist: "",
    Total: 111,
    Average: 2.921052632,
    impressoinCount: 38,
    commentCount: 1,
    averageWeight: 0.5842105263,
    totalAve: 111.5842105,
    rank: 103,
    isElected: "FALSE",
  },
  {
    number: 63,
    Team: "NOISE / GLITCH党",
    Genre: "Downtempo Broken Drumfunk",
    Title: "Online Girlfriends",
    Artist: "RIN",
    subArtist: "",
    Total: 109,
    Average: 3.205882353,
    impressoinCount: 34,
    commentCount: 0,
    averageWeight: 0.6411764706,
    totalAve: 109.6411765,
    rank: 104,
    isElected: "FALSE",
  },
  {
    number: 24,
    Team: "歪み党",
    Genre: "Mainstream Hardcore",
    Title: "Ash of the Fallen",
    Artist: "VarN∅THia",
    subArtist: "Obj. AZVW_2, Orion π;  BGI. Dolag",
    Total: 105,
    Average: 2.763157895,
    impressoinCount: 38,
    commentCount: 1,
    averageWeight: 0.5526315789,
    totalAve: 105.5526316,
    rank: 105,
    isElected: "FALSE",
  },
  {
    number: 35,
    Team: "混合拍子党",
    Genre: "Parallel in air",
    Title: "Waterfall basin",
    Artist: "KuSaRy",
    subArtist: "",
    Total: 105,
    Average: 2.692307692,
    impressoinCount: 39,
    commentCount: 0,
    averageWeight: 0.5384615385,
    totalAve: 105.5384615,
    rank: 106,
    isElected: "FALSE",
  },
  {
    number: 92,
    Team: "Kawaii党",
    Genre: "KAWAII FUTURE BASS",
    Title: "fille",
    Artist: "Fezike",
    subArtist: "",
    Total: 103,
    Average: 2.942857143,
    impressoinCount: 35,
    commentCount: 1,
    averageWeight: 0.5885714286,
    totalAve: 103.5885714,
    rank: 107,
    isElected: "FALSE",
  },
  {
    number: 115,
    Team: "メロディ党♪",
    Genre: "Art core",
    Title: "Unpalatable truth",
    Artist: "かつおぶし",
    subArtist: "",
    Total: 102,
    Average: 3,
    impressoinCount: 34,
    commentCount: 2,
    averageWeight: 0.6,
    totalAve: 102.6,
    rank: 108,
    isElected: "FALSE",
  },
  {
    number: 116,
    Team: "NOISE / GLITCH党",
    Genre: "HARSH NOISE",
    Title: "Ultra Bigots",
    Artist: "Yoshitoshi Abo",
    subArtist: "",
    Total: 98,
    Average: 2.578947368,
    impressoinCount: 38,
    commentCount: 2,
    averageWeight: 0.5157894737,
    totalAve: 98.51578947,
    rank: 109,
    isElected: "FALSE",
  },
  {
    number: 41,
    Team: "縦連打党",
    Genre: "Progressive House",
    Title: "Santiago",
    Artist: "AkA-110",
    subArtist: "",
    Total: 97,
    Average: 2.939393939,
    impressoinCount: 33,
    commentCount: 0,
    averageWeight: 0.5878787879,
    totalAve: 97.58787879,
    rank: 110,
    isElected: "FALSE",
  },
  {
    number: 114,
    Team: "縦連打党",
    Genre: "HARDCORE TATECHNO",
    Title: "TATENORI LOVERS",
    Artist: "nekomimi_STRaw",
    subArtist: "",
    Total: 93,
    Average: 3,
    impressoinCount: 31,
    commentCount: 0,
    averageWeight: 0.6,
    totalAve: 93.6,
    rank: 111,
    isElected: "FALSE",
  },
  {
    number: 87,
    Team: "NOISE / GLITCH党",
    Genre: "Experimental",
    Title: "Posterior Probability",
    Artist: "Idesia",
    subArtist: "",
    Total: 92,
    Average: 3.066666667,
    impressoinCount: 30,
    commentCount: 1,
    averageWeight: 0.6133333333,
    totalAve: 92.61333333,
    rank: 112,
    isElected: "FALSE",
  },
  {
    number: 91,
    Team: "歪み党",
    Genre: "Mystic LOLIGABBAR",
    Title: "Yes, My Littile Ma'am",
    Artist: "Ente++",
    subArtist: "",
    Total: 92,
    Average: 2.555555556,
    impressoinCount: 36,
    commentCount: 0,
    averageWeight: 0.5111111111,
    totalAve: 92.51111111,
    rank: 113,
    isElected: "FALSE",
  },
  {
    number: 107,
    Team: "Kawaii党",
    Genre: "kawaii futurebass",
    Title: "Metly Sugar Star",
    Artist: "たくあんしゃもじ",
    subArtist: "",
    Total: 91,
    Average: 3.033333333,
    impressoinCount: 30,
    commentCount: 2,
    averageWeight: 0.6066666667,
    totalAve: 91.60666667,
    rank: 114,
    isElected: "FALSE",
  },
  {
    number: 28,
    Team: "無所属",
    Genre: "dram＆piano",
    Title: "壱〇円",
    Artist: "scamp",
    subArtist: "",
    Total: 91,
    Average: 2.459459459,
    impressoinCount: 37,
    commentCount: 1,
    averageWeight: 0.4918918919,
    totalAve: 91.49189189,
    rank: 115,
    isElected: "FALSE",
  },
  {
    number: 76,
    Team: "冷飯党",
    Genre: "SV SPEEDCORE",
    Title: "632 SVCORE (Remake)",
    Artist: "Star*",
    subArtist: "",
    Total: 87,
    Average: 2.806451613,
    impressoinCount: 31,
    commentCount: 0,
    averageWeight: 0.5612903226,
    totalAve: 87.56129032,
    rank: 116,
    isElected: "FALSE",
  },
  {
    number: 117,
    Team: "UKHC党",
    Genre: "UK Hardcore",
    Title: "Master Sky",
    Artist: "Black201",
    subArtist: "",
    Total: 44,
    Average: 1.466666667,
    impressoinCount: 30,
    commentCount: 10,
    averageWeight: 0.2933333333,
    totalAve: 44.29333333,
    rank: 117,
    isElected: "FALSE",
  },
];

const teams = [
  "猿トランス党",
  "Trance党",
  "冷飯党",
  "メロディ党♪",
  "ユーロビー党",
  "GAY FURRIES UNITED",
  "縦連打党",
  "混合拍子党",
  "UKHC党",
  "Kawaii党",
  "NOISE / GLITCH党",
  "WESTERN党",
  "和風党",
  "Djen党",
  "歪み党",
  "シンフォニック党",
  "俺の推しを見ろ党",
  "ハウス党",
  "ポップス党",
  "銀行強党2",
  "無所属",
];

const grouped = groupBy(data, (value) => value.Team);
const arr = map(grouped, (v, k) => ({ team: k, bmses: v }));
const sorted = sortBy(arr, (item) => teams.indexOf(item.team));
